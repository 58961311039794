import React, { useState, useEffect }  from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Link} from 'react-router-dom'
import DownloadPopulationButton from "./downloadButton";


import './App.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


export default function MaxWidthDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        fetchDetails();
    }, []);

    const [detail, setDetail] = useState({})

    const fetchDetails = async () => {
        const fetchDetails = await fetch(
            '/api/get_alleleinfo?g='+props.genename+''
        );
        const detail = await fetchDetails.json()
        setDetail(detail)
    }

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
    }))(TableRow);

    var clickDefault = <Button variant="outlined" color="primary" onClick={handleClickOpen}>{props.content}</Button>
    if (props.type === 'link'){
        clickDefault = <a onClick={handleClickOpen}>{props.content}</a>
    }

    /*var downloadTxtFile = (allele, sequence) => {
        const element = document.createElement("a");
        var fastaformat = '>'+allele+'\n'+sequence
        const file = new Blob([fastaformat], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = allele+".fasta";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }*/

    return (
        <React.Fragment>
            {clickDefault}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    <i>{props.genename}</i>{props.linkparent === "true" ? <Link to={`/gene/${props.genename.split("_")[0]}`} className="popuplink">View gene page</Link>: "" }
                    <div className="clclass">
                        <DownloadPopulationButton title={"Download annotation for "+props.genename} chain={props.genename} />
                        {detail.Level === 'AS1' &&
                        <Tooltip title='Known allele found in IMGT' placement="top" arrow>
                            <div className={detail.Level}>{detail.Level}</div>
                        </Tooltip>
                        }
                        {detail.Level === 'AS2' &&
                        <Tooltip title='>20 supporting haplotypes' placement="top" arrow>
                            <div className={detail.Level}>{detail.Level}</div>
                        </Tooltip>
                        }
                        {detail.Level === 'AS3' &&
                        <Tooltip title='>7 <20 supporting haplotypes' placement="top" arrow>
                            <div className={detail.Level}>{detail.Level}</div>
                        </Tooltip>
                        }
                    </div>
                    <Divider className="divider-bottom" />
                </DialogTitle>
                <DialogContent>

                    <ExpansionPanel expanded={true}>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Sequence</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <p className="seq">
                                {detail.Sequence}
                            </p>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Divider />
                    {detail.support ?
                        detail.support.map(function (support, i) {
                            return <React.Fragment key={support.name}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>{support.description} support: {support.support}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Table className={classes.table} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Population</StyledTableCell>
                                                    <StyledTableCell align="right">Support</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {support.subpopulations.map((population)=>
                                                    <StyledTableRow key={population.name}>
                                                        <StyledTableCell component="th" scope="row">
                                                            <Tooltip title={population.description} aria-label="add" placement='right' arrow>
                                                                <div className="population-name">{population.name}</div>
                                                            </Tooltip>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">{population.support}</StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </React.Fragment>
                        })
                        : "No information found"
                    }
                    <Divider />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}