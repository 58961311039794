import React, { useState, useEffect }  from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import AllelePopup from './allelePopup'
import Tooltip from '@material-ui/core/Tooltip';
import MenuBar from './menuBar'
import './App.css';
import DownloadPopulationButton from "./downloadButton";
import MultipleSequenceViewer from "./multipleSequenceViewer";

function GeneDetail({ match }) {

    useEffect(() => {
        fetchDetails();
        fetchAlignments();
    }, []);

    const [detail, setDetail] = useState({})
    const [sequences, setSequences] = useState([])
    const [mutations, setMutations] = useState([])

    const fetchDetails = async () => {
        const fetchDetails = await fetch(
            '/api/get_genedetail?g='+match.params.genename+''
        );
        const detail = await fetchDetails.json()
        setDetail(detail)
        console.log(detail)
        //setAlleles(detail.alleles)
    }

    const fetchAlignments = async () => {
        const fetchAlignments = await fetch(
            '/api/get_alignments?gene='+match.params.genename+''
        );
        const sequences = await fetchAlignments.json()
        setSequences(sequences.seq)
        setMutations(sequences.mutations)
    }


    return(
        <div>
            <MenuBar />
            <div className="main-content">
                <Container maxWidth="lg">
                    <div className="grid-container">
                        <Paper>
                            <div className="grid-title">
                                <Typography variant="h4" className="float_left">Gene: <i>{detail.geneName}</i> {detail.functionality === 'Functional' ? '[F]' : detail.functionality === 'Pseudogene' ? '[P]' : detail.functionality === 'Operationally Indistinguishable gene' ? '[OI]' : ''}</Typography>
                                <DownloadPopulationButton title={"Download annotation for " + detail.geneName}
                                                          chain={detail.geneName} className="float_right"/>
                            </div>
                            <div className="grid-subtitle">
                                {detail.alleles ? (<div><b>Number of alleles: </b>{detail.alleles.length}</div> ) : null}
                                {detail.note ? (<div dangerouslySetInnerHTML={{ __html: "<b>Note: </b>"+detail.note }} />) : null}
                            </div>
                            <div className="grid-content">
                                {sequences.length && sequences && mutations && mutations.length ? (
                                    <div>
                                        <Divider />
                                        <Typography variant='h5'><br />Sequence alignment<br/></Typography>
                                        <MultipleSequenceViewer sequences={sequences} mutations={mutations}/>
                                    </div>
                                    ) : ""}
                            </div>
                        </Paper>
                        <div className="grid-alleles">
                            {detail.alleles ?
                                detail.alleles.map(function (allele, i) {
                                    return <React.Fragment key={allele.Number}>
                                        <Card className="allele-container">
                                            <CardContent >
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    <i>{detail.geneName}_{allele.Number}</i>
                                                    {allele.Level === 'AS1' &&
                                                    <div className="clclass">
                                                        <Tooltip title='Known allele found in IMGT' placement="top" arrow>
                                                            <div className={allele.Level}>{allele.Level}</div>
                                                        </Tooltip>
                                                    </div>
                                                    }
                                                    {allele.Level === 'AS2' &&
                                                    <div className="clclass">
                                                        <Tooltip title='>20 supporting haplotypes' placement="top" arrow>
                                                            <div className={allele.Level}>{allele.Level}</div>
                                                        </Tooltip>
                                                    </div>
                                                    }
                                                    {allele.Level === 'AS3' &&
                                                    <div className="clclass">
                                                        <Tooltip title='>7 <20 supporting haplotypes' placement="top" arrow>
                                                            <div className={allele.Level}>{allele.Level}</div>
                                                        </Tooltip>
                                                    </div>
                                                    }
                                                    <Divider />
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p" className="seq">{allele.Sequence}</Typography>
                                            </CardContent>
                                            <CardActions>
                                                <AllelePopup genename={detail.geneName +"_"+ allele.Number} content={detail.geneName +"_"+ allele.Number+" details"} />
                                            </CardActions>
                                        </Card>
                                    </React.Fragment>
                                })
                                : (<p>No alleles found.</p>)}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default GeneDetail;
