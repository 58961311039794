import React from 'react';
import Home from './Home'
import GeneDetail from './GeneDetail'
import BlastResult from './blastResult'
import Blast from './blast'
import Documentation from './documentation'
import Analytics from './analytics'
import {BrowserRouter as Router, Route } from 'react-router-dom'

function App() {
    return(
    <Router>
        <Route path='/' exact component={Home}/>
        <Route path='/seqsearch' exact component={Blast}/>
        <Route path='/results/' component={BlastResult}/>
        <Route path='/analytics/' component={Analytics}/>
        <Route path='/documentation' exact component={Documentation}/>
        <Route path='/gene/:genename' component={GeneDetail}/>
    </Router>
    )
}

export default App;
