import React from 'react';
import Container from '@material-ui/core/Container';
import MenuBar from './menuBar'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './App.css';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import AllelePopup from "./allelePopup";
import TableContainer from "@material-ui/core/TableContainer";

function Documentation() {
    return(
    <div>
        <MenuBar />
        <div className="main-content">
            <Container maxWidth="lg">
            <Paper className="homepaper">
                <div className="block">
                    <Typography gutterBottom variant="h4" className="thesis-text">
                        pmTRIG database: population matched germline alleles for T-cell receptor and immunoglobulin loci
                    </Typography>
                    <Typography gutterBottom variant="body1" className="thesis-text">
                        pmTRIG database is a population matched database for full-length germline alleles from T-cell receptor and immunoglobulin loci of human. The database comprises highly accurate alleles for Variable, Diversity, Joining and Constant genes extracted from 1000 genomes phased variants. 1000 Genomes comprises information from ~2500 individuals from 26 populations belonging to five super-populations i.e. African, American, East Asians, Europeans and South Asians. The alleles are divided into three allele sets:
                        <br /><br />
                    </Typography>
                    <TableContainer component={Paper} className="thesis-text">
                        <Table className="classes.table" aria-label="simple table">
                            <TableBody>
                                <TableRow key="1">
                                    <TableCell align="left">AS1</TableCell>
                                    <TableCell scope="row">Known sequences</TableCell>
                                </TableRow>
                                <TableRow key="2">
                                    <TableCell align="left">AS2</TableCell>
                                    <TableCell scope="row">Novel frequent alleles</TableCell>
                                </TableRow>
                                <TableRow key="3">
                                    <TableCell align="left">AS3</TableCell>
                                    <TableCell scope="row">Novel rare alleles</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography gutterBottom variant="body1" className="thesis-text">
                        <br/>Owing to high duplication and divergence events in immunoglobulin locus, each of the above-mentioned category is further divided into three sub-categories:
                        <br /><br />
                    </Typography>
                    <TableContainer component={Paper} className="thesis-text">
                        <Table className="classes.table" aria-label="simple table">
                            <TableBody>
                                <TableRow key="1">
                                    <TableCell align="left">SE</TableCell>
                                    <TableCell scope="row">Alleles for Self-evident genes</TableCell>
                                </TableRow>
                                <TableRow key="2">
                                    <TableCell align="left">GA</TableCell>
                                    <TableCell scope="row">Alleles for group genes</TableCell>
                                </TableRow>
                                <TableRow key="3">
                                    <TableCell align="left">OI</TableCell>
                                    <TableCell scope="row">Alleles for operationally indistinguishable genes</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography gutterBottom variant="body1" className="thesis-text">
                        <br/>
                        This allows flexibility to the user to choose the sets carefully for subsequent validation.
                        <br /><br />
                        The alleles can be accessed either by the direct interface or by BLAST query interface. The search can be directly directed to the alleles, their sequence and the frequency of alleles in 26 populations.<br/><br/>
                    </Typography>
                    <Typography gutterBottom variant="h5" className="thesis-text">
                        Automated tool to identify the germline alleles
                    </Typography>
                    <Typography gutterBottom variant="body1" className="thesis-text">
                        An automated tool is developed to identify alleles present in at least 2 individuals to be included in this resource. The tool provides flexibility to the user to use any threshold to identify alleles and it can be applied to any gene in human genome with no limitation to the exons in the genes. Furthermore, our tool is optimized to use up to three multiple alternate bases to reference base in the phased vcf file to call alleles.<br/><br/>
                    </Typography>
                    <Typography gutterBottom variant="h5" className="thesis-text">
                        References
                    </Typography>
                    <Typography gutterBottom variant="body1" className="thesis-text">
                        •	If you publish results obtained using pmIG database, please cite:<br/>

                        Population matched (PM) germline allelic variants of immunoglobulin (IG) loci: New pmIG database to better understand IG repertoire and selection processes in disease and vaccination Khatri I, Berkowska MA, van den Akker EB, Teodosio C, Reinders MJT and Van Dongen JJM. BioRxiv, 2020.
                        <br/><br/>
                        •	If you publish results obtained using pmTR database, please cite:<br/>
                        <a href="https://github.com/JulianDekker/TR_diversity"> https://github.com/JulianDekker/TR_diversity</a><br/><br/>
                    </Typography>
                    <Typography gutterBottom variant="h5" className="thesis-text">
                        Contact information:
                    </Typography>
                    <Typography gutterBottom variant="body1" className="thesis-text">
                        Prof. Jacques J.M. van Dongen, MD, PhD<br />
	                Email: <a href="mailto:J.J.M.van_Dongen@lumc.nl">J.J.M.van_Dongen@lumc.nl</a><br/><br />
	                Indu Khatri, PhD<br />
                        Email: <a href="mailto:i.khatri@lumc.nl">i.khatri@lumc.nl</a><br/>
                    </Typography>
                </div>
            </Paper>
            </Container>
        </div>
    </div>
    );
}

export default Documentation;
