import React from 'react';
import './App.css';


class MultipleSequenceViewer extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            alignmentobj: null,
            seq: this.props.sequences,
            mutation: this.props.mutations
        };
    }

    componentDidMount() {
        const splitSequences = function (sequences, mutations) {
            // sequence in 60ch rows for each allele and highlights mutations
            var objs = [];
            var mutatedchunks = []
            // split alleles
            sequences.map(function (seq) {
                objs.push({name: seq.name, sequenceChunks: seq.sequence.match(/.{1,60}/g)});
            })
            // check in what chunks to insert the mutation highlighting
            mutations.map(function (position) {
                mutatedchunks.push(Math.ceil(position / 60) - 1)
            })
            objs.map(function (item) {
                let newchunks = []
                item.sequenceChunks.map(function (chunks, i) {
                    //split string to list of single characters
                    var chunk = chunks.split("")
                    if (mutatedchunks.includes(i)) {
                        // filter out mutations outside the current chunk
                        var filter = mutations.filter(function (mutations) {
                            return mutations > i * 60 && mutations < (i * 60) + 60
                        })
                        // apply mutation to position in chunk
                        filter.map(function (mutation) {
                            chunk[mutation % 60] = "<span>" + chunk[mutation % 60] + "</span>"
                        })
                        //save new chunk back to string
                        newchunks.push(chunk.join(""))
                    }
                    else{
                        //save unchanged chunk back to string
                        newchunks.push(chunk.join(""))
                    }
                })
                //update entire item with highlighted chunks
                item.sequenceChunks = newchunks
            })
            return objs
        }
        //update state
        this.setState({"alignmentobj": splitSequences(this.state.seq, this.state.mutation)})
    }

    render(){
        /*function range(start, end){
            return Array.from({length: end - start}, (_, i) => {
                return (start+i) % 2 === 0 ? (start+i+1) : " "
            })
        }*/
        //console.log(range(1,60))
        if (this.state.alignmentobj){
            var build = []
            const renderrows = () => {
                //iterate over number of chunks
                for (var iter=0; iter < this.state.alignmentobj["0"].sequenceChunks.length; iter++){
                    //iter over number of alleles
                    this.state.alignmentobj.map(function (item, i) {
                        build.push(
                            {name: item.name, chunk: item.sequenceChunks[iter]}
                        )
                    })
                }
            }
            renderrows()
        }
        //apply margin everytime the next allele starts
        var marginTop = (number, chunk) => {
            let index = number.index / this.state.alignmentobj.length
            //var offset = ((index*60)+1).toString().length + ((index*60)+60).toString().length //(temp.match(/is/g) || []).length;
            var rowlen = chunk.length - ((chunk.match(/<span>/g) || []).length * 13)
            var rowlabel = ((index*60)+1)+" .. "+((index*60)+rowlen)
            //var rowlabel = ((index*60)+1)+".."+((index*60)+60)
            return number.index % this.state.alignmentobj.length === 0 ? (
                <div className="MSA-row MarginTop">
                    <div className="MSA-labels">{rowlabel}</div>
                    <div className="seq" />
                </div>
            ) : ""
        }

        return(
            <div className="MSA-container">
                {build ? (
                    <div>
                        {build.map(function (object, index) {
                            return (
                                <div key={index}>
                                    {marginTop({index}, object.chunk)}
                                    <div className="MSA-row">
                                        <div className="MSA-labels">{object.name}</div>
                                        <div className="seq" dangerouslySetInnerHTML={{ __html: object.chunk }} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : "Couldn't load msa viewer"}
            </div>
        )
    }

}

export default MultipleSequenceViewer


// import React, { useState, useEffect }  from 'react';
// import './App.css';
//
// function MultipleSequenceViewer(props) {
//
//     const [tester, setTester] = useState([])
//
//     const splitSequences = function(sequences, mutations) {
//         var objs = [];
//         var mutatedchunks = []
//         sequences.map(function (seq) {
//             objs.push({name: seq.name, sequenceChunks: seq.sequence.match(/.{1,60}/g)});
//         })
//         mutations.map(function (position) {
//             mutatedchunks.push(Math.ceil(position / 60)-1)
//         })
//         objs.map(function (item) {
//             let newchunks= []
//             item.sequenceChunks.map(function (chunks, i) {
//                 var chunk = chunks.split("")
//                 if (mutatedchunks.includes(i)){
//                     var filter = mutations.filter(function (mutations) {
//                         return mutations > i*60 && mutations < (i*60) + 60
//                     })
//                     filter.map(function (mutation) {
//                         chunk[mutation % 60] = "<span>"+chunk[mutation % 60]+"</span>"
//                     })
//                     newchunks.push(chunk.join(""))
//                 }
//             })
//             item.sequenceChunks = newchunks
//         })
//
//     }
//     setTester(splitSequences(props.sequences, props.mutations));
//     if (props.sequences.length && props.sequences){
//
//         //console.log(tester)
//     }
//
//
//     return(
//         <div className="MSA-container">
//             {console.log('----', tester)}
//             <div className="MSA-row">
//                 <div className="MSA-labels"></div>
//                 <div className="MSA-sequences">
//
//                 </div>
//             </div>
//         </div>
//     );
// }

//export default MultipleSequenceViewer;
