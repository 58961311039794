import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {Redirect} from 'react-router-dom'

const useStyles = makeStyles({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function SimpleCard() {
    const classes = useStyles();
    const [redirect, setRedirect] = React.useState(false)
    const [value, setValue] = React.useState('');
    const [blastresults, setBlastResults] = React.useState([])

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: '',
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (value != null){
            requestOptions.body = JSON.stringify({sequence: value})
            fetch('/api/igblast', requestOptions)
                .then(response => response.json())
                .then(data => {
                    setBlastResults(data)
                    setRedirect(true);
                });
        }
    };
    if (redirect === true) {
        return (<Redirect to={{pathname: '/results', state: {results: blastresults}}} />)
    }
    else{
        return(
            <div>
                <Typography className={classes.title+" seqSearch"} color="textSecondary" gutterBottom>
                    Sequence search
                </Typography>
                <form  noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        id="filled-multiline-flexible"
                        label="Nucleotide sequence"
                        multiline
                        rows="4"
                        value={value}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        variant="outlined"
                        className="igblastinput seq"
                    />
                    <Button variant="contained" size="medium" color="primary" className={classes.margin} onSubmit={handleSubmit} type='submit'>
                        Search sequence
                    </Button>
                </form>
            </div>
        );
    }
}