import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNext from '@material-ui/icons/NavigateNext'
import {Link, Redirect} from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import './App.css';


class SearchForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            data: [],
            redirect: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
        if (!this.state.value){
            this.setState({data: []})
        }
        console.log(this.state.value)
    }

    handleSubmit(event) {
        //alert('A name was submitted: ' + this.state.value);
        if (this.state.value){
            if (this.state.value.includes("_")){
                this.setState({redirect: true})
            }
            else{
                fetch('/api/search_gene?q='+this.state.value).then(res => res.json()).then(data => {
                    this.setState({data: data.results});
                });
            }
            event.preventDefault();
        }
        else{
            alert("Please fill in a search term before submitting.")
        }
    }

    render(){
        const redirect = this.state.redirect;
        if (redirect === true) {
            return <Redirect to={'/gene/'+this.state.value.split('_')[0]} />
        }
        const nresults = this.state.data.length;
        return (
        <div>
            <Paper component="form" onSubmit={this.handleSubmit} className='searchbar'>
                <InputBase
                    className="input"
                    placeholder="search the database for genes.."
                    inputProps={{ 'aria-label': this.state.value }}
                    onChange={this.handleChange}
                />
                <IconButton type="submit" aria-label="search" className="iconButton" >
                <SearchIcon  />
                </IconButton>
            </Paper>
            {nresults > 0 ? (
            <div className="results">
            <Paper>
            <List className="results" subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Matched {nresults} genes to query.
                </ListSubheader>
              } component="nav">
                 {this.state.data.map(gene => (
                    <React.Fragment key={gene}>
                         <ListItem button className="result-item" >
                            <ListItemText>
                                <Link to={`/gene/${gene}`}>{gene}</Link>
                            </ListItemText>
                            <ListItemIcon>
                              <NavigateNext />
                            </ListItemIcon>
                         </ListItem>
                         <Divider className="nomargin" />
                     </React.Fragment>
                 ))}
            </List>
            </Paper >
            </div>
            ) : ''}
        </div>
        )
    }

}

export default SearchForm