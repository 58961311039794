import React from 'react';
import Container from '@material-ui/core/Container';
import MenuBar from './menuBar'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BlastSearch from './igblast'
import './App.css';

function Blast() {
    return(
    <div>
        <MenuBar />
        <div className="main-content">
            <Container maxWidth="md">
            <Paper className="homepaper">
                <div className="block">
                    <Typography gutterBottom variant="h4">
                        Search by sequence
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sequence search is enabled by Basic Local Alignment Search Tool algorithm for nucleotide sequences (BLASTN). You can use the input form below to search TR/IG genes/allele sequences within our database. <br/><br/>
                        Input nucleotide sequences are accepted in form of raw bases or Fasta format. Batch search is also enabled.
                    </Typography>
                    <Divider />
                    <BlastSearch />
                </div>
            </Paper>
            </Container>
        </div>
    </div>
    );
}

export default Blast;
