import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import './App.css';

function DownloadPopulationButton(props) {

    var downloadPopulationFile = (chain) => {
        if (chain != null){
            fetch(
                '/api/download_chaindata?chain='+ chain
            ).then(res => res.json()).then(data => {
                    const element = document.createElement("a");
                    //const header = 'Gene\tConfidence_score\tSequence\tACB\tASW\tBEB\tCDX\tCEU\tCHB\tCHS\tCLM\tESN\tFIN\tGBR\tGIH\tGWD\tIBS\tITU\tJPT\tKHV\tLWK\tMSL\tMXL\tPEL\tPJL\tPUR\tSTU\tTSI\tYRI\n'
                    const file = new Blob([data.result], {type: 'text/plain'});
                    element.href = URL.createObjectURL(file);
                    element.download = chain+"_annotation.tsv";
                    document.body.appendChild(element); // Required for this to work in FireFox
                    element.click();
                }
            );

        }
    }

    return(
        <Tooltip title={props.title} placement="top" arrow className={props.className}>
            <GetAppIcon onClick={() => { downloadPopulationFile(props.chain) }} className="downloadicon" />
        </Tooltip>
    );
}

export default DownloadPopulationButton;
