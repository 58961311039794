import React from 'react';
import Container from '@material-ui/core/Container';
import SearchForm from './searchForm'
import MenuBar from './menuBar'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom'
import './App.css';

function Home() {
    return(
    <div>
        <MenuBar />
        <div className="main-content">
            <Container maxWidth="md">
            <Paper className="homepaper">
                <div className="block">
                    <Typography gutterBottom variant="h2">
                        pmTRIG database
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        The germline alleles for TR and IG (Variable, Diversity, Joining and Constant) genes are organized from approximately 2500 individuals from 26 different ethnicities representing five major super populations.
                        <br /><br />
	                For more information on how we obtained our results please refer to the <Link to='/documentation'className="textlink">documentation</Link> section.
                        <br /><br />
                    </Typography>
                    <Divider />
                    <Typography gutterBottom variant="h6">
                        Search by family, gene or allele name.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <br/><br />
                        Examples "TRA", "IGHC", "TRBV1-1". <br /><br />        
	    </Typography>
                    <SearchForm />
                </div>
            </Paper>
            </Container>
        </div>
    </div>
    );
}

export default Home;
