import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import StorageIcon from '@material-ui/icons/Storage';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {Link} from 'react-router-dom'


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 10,
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
  });

  const toggleDrawer = (val) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open: val });
  };

  const list = (
      <div
          className={clsx(classes.list)}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary='Home' />
          </ListItem>
          <ListItem button component={Link} to="/seqsearch">
            <ListItemIcon><StorageIcon /></ListItemIcon>
            <ListItemText primary='Sequence search' />
          </ListItem>
          <ListItem button component={Link} to="/Analytics">
            <ListItemIcon><AssessmentIcon /></ListItemIcon>
            <ListItemText primary='Analytics' />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key='Documentation' component={Link} to="/documentation">
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary='Documentation' />
          </ListItem>
        </List>
      </div>
  );

  return (
      <div>
        <div className={classes.root}>
          <AppBar position="fixed">
            <Toolbar variant="dense">
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                pmTRIG database
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <React.Fragment>
          <Drawer anchor='left' open={state['open']} onClose={toggleDrawer(false)}>
            {list}
          </Drawer>
        </React.Fragment>
      </div>
  );
}
