import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import AllelePopup from './allelePopup'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import MenuBar from './menuBar';
import GetAppIcon from "@material-ui/icons/GetApp";
import './App.css';

function BlastResult(result) {
    const [blastresult] = React.useState(result.location.state)

    const downloadBlast = () => {
        const element = document.createElement("a");
        const file = new Blob([blastresult.results.file.content], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = blastresult.results.file.id+"_blastres.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return(
        <div>
            <MenuBar />
            <div className="main-content">
                <Container maxWidth="lg">
                    <Paper className="homepaper">
                        <div className="block">
                            <Typography gutterBottom variant="h4">
                                Search results
                            </Typography>
                            <Tooltip title="Download search results" placement="top" arrow>
                                <GetAppIcon onClick={() => { downloadBlast() }} className="downloadicon" />
                            </Tooltip>
                            <TableContainer component={Paper}>
                                <Table className="classes.table" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Query</TableCell>
                                            <TableCell>Gene</TableCell>
                                            <TableCell align="right">Max score</TableCell>
                                            <TableCell align="right">Total score</TableCell>
                                            <TableCell align="right">Gaps</TableCell>
                                            <TableCell align="right">Mismatches</TableCell>
                                            <TableCell align="right">Start</TableCell>
                                            <TableCell align="right">End</TableCell>
                                            <TableCell align="right">E value</TableCell>
                                            <TableCell align="right">Per. ident</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {blastresult.results.results.map(function(item, i){
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell align="left" className="qseqid-cell"><div><Tooltip title={item.qseqid} placement="top" arrow><div className="qseqid">{item.qseqid}</div></Tooltip><div className='moredots'>{item.qseqid.length > 10 ? '..' : ''}</div></div></TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <b><AllelePopup genename={item.sseqid} content={item.sseqid} type="link" linkparent="true" /></b>
                                                    </TableCell>
                                                    <TableCell align="right">{item.bitscore}</TableCell>
                                                    <TableCell align="right">{item.score}</TableCell>
                                                    <TableCell align="right">{item.gaps}</TableCell>
                                                    <TableCell align="right">{item.mismatch}</TableCell>
                                                    <TableCell align="right">{item.sstart}</TableCell>
                                                    <TableCell align="right">{item.send}</TableCell>
                                                    <TableCell align="right">{item.evalue}</TableCell>
                                                    <TableCell align="right">{item.ppos}%</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                </Container>
            </div>
        </div>
    );
}

export default BlastResult;
