import React, { useState, useEffect }  from 'react';
import Container from '@material-ui/core/Container';
import MenuBar from './menuBar'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DownloadPopulationButton from "./downloadButton";
import './App.css';

function Analytics() {

    useEffect(() => {
        fetchDetails();
    }, []);

    const [detail, setDetail] = useState({})

    const fetchDetails = async () => {
        const fetchDetails = await fetch(
            '/api/get_chaincounts'
        );
        const detail = await fetchDetails.json()
        setDetail(detail)
    }

    const addTotal = function(total, element) {
        total += Number(element[1])
        return total
    }

    return(
        <div>
            <MenuBar />
            <div className="main-content">
                <Container maxWidth="md">
                    <Paper className="homepaper">
                        <div className="block">
                            <Typography gutterBottom variant="h4">
                                Download Alleles for each chain and class individually.
                            </Typography>
                            <Typography gutterBottom variant="h5">
                                T-cell receptor genes:
                            </Typography>
                            {detail.results ? (
                                <div className="Analytics-tables">

				    {detail.results.TRAV && detail.results.TRAJ && detail.results.TRAC ? (<TableContainer component={Paper}>
                                        <Typography variant="h6" gutterBottom className="float_left">
                                            <i>TRA</i>
                                        </Typography>
                                        <DownloadPopulationButton title='Download all annotations for TRA' chain="TRA" className="float_right Analytics-title-icon" />
                                        <Table className="classes.table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Segment</TableCell>
                                                    <TableCell align="center">AS1</TableCell>
                                                    <TableCell align="center">AS2</TableCell>
                                                    <TableCell align="center">AS3</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">TRAV</TableCell>
                                                    <TableCell align="center">{detail.results.TRAV.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRAV.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRAV.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRAV).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRAV' chain="TRAV" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRAJ</TableCell>
                                                    <TableCell align="center">{detail.results.TRAJ.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRAJ.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRAJ.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRAJ).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRAJ' chain="TRAJ" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRAC</TableCell>
                                                    <TableCell align="center">{detail.results.TRAC.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRAC.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRAC.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRAC).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRAC' chain="TRAC" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>): ""}
				    {detail.results.TRBV && detail.results.TRBD && detail.results.TRBC && detail.results.TRBJ ? (<TableContainer component={Paper}>
                                        <Typography variant="h6" gutterBottom className="float_left">
                                            <i>TRB</i>
                                        </Typography>
                                        <DownloadPopulationButton title='Download all annotations for TRB' chain="TRB" className="float_right Analytics-title-icon" />
                                        <Table className="classes.table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Segment</TableCell>
                                                    <TableCell align="center">AS1</TableCell>
                                                    <TableCell align="center">AS2</TableCell>
                                                    <TableCell align="center">AS3</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">TRBV</TableCell>
                                                    <TableCell align="center">{detail.results.TRBV.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBV.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBV.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRBV).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRBV' chain="TRBV" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRBD</TableCell>
                                                    <TableCell align="center">{detail.results.TRBD.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBD.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBD.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRBD).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRBD' chain="TRBD" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRBJ</TableCell>
                                                    <TableCell align="center">{detail.results.TRBJ.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBJ.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBJ.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRBJ).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRBJ' chain="TRBJ" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRBC</TableCell>
                                                    <TableCell align="center">{detail.results.TRBC.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBC.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRBC.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRBC).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRBC' chain="TRBC" />
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>): ""}
				    {detail.results.TRGV && detail.results.TRGJ && detail.results.TRGC ? (<TableContainer component={Paper}>
                                        <Typography variant="h6" gutterBottom className="float_left">
                                            <i>TRG</i>
                                        </Typography>
                                        <DownloadPopulationButton title='Download all annotations for TRG' chain="TRG" className="float_right Analytics-title-icon" />
                                        <Table className="classes.table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Segment</TableCell>
                                                    <TableCell align="center">AS1</TableCell>
                                                    <TableCell align="center">AS2</TableCell>
                                                    <TableCell align="center">AS3</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">TRGV</TableCell>
                                                    <TableCell align="center">{detail.results.TRGV.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRGV.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRGV.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRGV).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRGV' chain="TRGV" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRAJ</TableCell>
                                                    <TableCell align="center">{detail.results.TRGJ.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRGJ.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRGJ.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRGJ).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRGJ' chain="TRGJ" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRGC</TableCell>
                                                    <TableCell align="center">{detail.results.TRGC.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRGC.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRGC.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRGC).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRGC' chain="TRGC" />
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>): ""}
				    {detail.results.TRDV && detail.results.TRDD && detail.results.TRDJ && detail.results.TRDC ? (<TableContainer component={Paper}>
                                        <Typography variant="h6" gutterBottom className="float_left">
                                            <i>TRD</i>
                                        </Typography>
                                        <DownloadPopulationButton title='Download all annotations for TRD' chain="TRD" className="float_right Analytics-title-icon" />
                                        <Table className="classes.table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Segment</TableCell>
                                                    <TableCell align="center">AS1</TableCell>
                                                    <TableCell align="center">AS2</TableCell>
                                                    <TableCell align="center">AS3</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">TRDV</TableCell>
                                                    <TableCell align="center">{detail.results.TRDV.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDV.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDV.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRDV).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRDV' chain="TRDV" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRDD</TableCell>
                                                    <TableCell align="center">{detail.results.TRDD.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDD.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDD.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRDD).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRDD' chain="TRDD" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRDJ</TableCell>
                                                    <TableCell align="center">{detail.results.TRDJ.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDJ.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDJ.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRDJ).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRDJ' chain="TRDJ" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">TRDC</TableCell>
                                                    <TableCell align="center">{detail.results.TRDC.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDC.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.TRDC.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.TRDC).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for TRDC' chain="TRDC" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>) : ""}
                                </div>
                            ) : "Error loading data"}
	    		{detail.results ? (<React.Fragment>                
	    			<Typography gutterBottom variant="h5">
                                	<br />B-cell receptor genes:
                            	</Typography>
                            
                                <div className="Analytics-tables">

				{detail.results.IGKV && detail.results.IGKJ && detail.results.IGKC ? (<TableContainer component={Paper}>
                                        <Typography variant="h6" gutterBottom className="float_left">
                                            <i>IGK</i>
                                        </Typography>
                                        <DownloadPopulationButton title='Download all annotations for IGK' chain="IGK" className="float_right Analytics-title-icon" />
                                        <Table className="classes.table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Segment</TableCell>
                                                    <TableCell align="center">AS1</TableCell>
                                                    <TableCell align="center">AS2</TableCell>
                                                    <TableCell align="center">AS3</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">IGKV</TableCell>
                                                    <TableCell align="center">{detail.results.IGKV.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGKV.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGKV.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGKV).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGKV' chain="IGKV" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">IGKJ</TableCell>
                                                    <TableCell align="center">{detail.results.IGKJ.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGKJ.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGKJ.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGKJ).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGKJ' chain="IGKJ" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">IGKC</TableCell>
                                                    <TableCell align="center">{detail.results.IGKC.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGKC.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGKC.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGKC).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGKC' chain="IGKC" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>): ""}
				{detail.results.IGLV && detail.results.IGLJ && detail.results.IGLC ? (<TableContainer component={Paper}>
                                        <Typography variant="h6" gutterBottom className="float_left">
                                            <i>IGL</i>
                                        </Typography>
                                        <DownloadPopulationButton title='Download all annotations for IGL' chain="IGL" className="float_right Analytics-title-icon" />
                                        <Table className="classes.table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Segment</TableCell>
                                                    <TableCell align="center">AS1</TableCell>
                                                    <TableCell align="center">AS2</TableCell>
                                                    <TableCell align="center">AS3</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">IGLV</TableCell>
                                                    <TableCell align="center">{detail.results.IGLV.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGLV.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGLV.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGLV).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGLV' chain="IGLV" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">IGLJ</TableCell>
                                                    <TableCell align="center">{detail.results.IGLJ.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGLJ.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGLJ.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGLJ).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGLJ' chain="IGLJ" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">IGLC</TableCell>
                                                    <TableCell align="center">{detail.results.IGLC.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGLC.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGLC.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGLC).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGLC' chain="IGLC" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>) : "" }
				{detail.results.IGHV && detail.results.IGHD && detail.results.IGHJ && detail.results.IGHC ? (<TableContainer component={Paper}>
                                        <Typography variant="h6" gutterBottom className="float_left">
                                            <i>IGH</i>
                                        </Typography>
                                        <DownloadPopulationButton title='Download all annotations for IGH' chain="IGH" className="float_right Analytics-title-icon" />
                                        <Table className="classes.table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Segment</TableCell>
                                                    <TableCell align="center">AS1</TableCell>
                                                    <TableCell align="center">AS2</TableCell>
                                                    <TableCell align="center">AS3</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">IGHV</TableCell>
                                                    <TableCell align="center">{detail.results.IGHV.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHV.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHV.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGHV).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGHV' chain="IGHV" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">IGHD</TableCell>
                                                    <TableCell align="center">{detail.results.IGHD.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHD.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHD.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGHD).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGHD' chain="IGHD" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">IGHJ</TableCell>
                                                    <TableCell align="center">{detail.results.IGHJ.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHJ.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHJ.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGHJ).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGHJ' chain="IGHJ" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">IGHC</TableCell>
                                                    <TableCell align="center">{detail.results.IGHC.AS1 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHC.AS2 || 0}</TableCell>
                                                    <TableCell align="center">{detail.results.IGHC.AS3 || 0}</TableCell>
                                                    <TableCell align="center">{Object.entries(detail.results.IGHC).reduce(addTotal, 0) || 0}</TableCell>
                                                    <TableCell align="center">
                                                        <DownloadPopulationButton title='Download annotation for IGHC' chain="IGHC" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer> ) : "" }
                                </div></React.Fragment>
                            ) : ""}

                        </div>
                    </Paper>
                </Container>
            </div>
        </div>
    );
}

export default Analytics;
